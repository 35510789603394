var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "article-wrap" }, [
    _c("div", { staticClass: "article-title" }, [
      _vm._v(" " + _vm._s(_vm.title ? _vm.title : "未找到对应文档") + " "),
    ]),
    _c("div", { staticClass: "content-wrap" }, [
      _c("div", { domProps: { innerHTML: _vm._s(_vm.content) } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }