<template>
  <div class="article-wrap">
    <div class="article-title">
      {{title ? title : '未找到对应文档'}}
    </div>
    <div class="content-wrap">
      <div v-html="content">

      </div>
    </div>
  </div>
</template>

<script>
  import {
    selectOne
  } from '../api'
  export default {
    computed: {
      title() {
        const types = {
          BZZX: '用户手册',
          PTGZ: '平台规则',
          HTMB: '合同模板',
          ZFFS: '支付方式',
        }
        return types[this.type]
      }
    },
    watch: {
      '$route': function (newV, oldV) {
        this.getArticle()
      }
    },
    created() {
      this.getArticle()
    },
    data() {
      return {
        content: '',
        type: '',
      }
    },
    methods: {

      getArticle() {
        return selectOne({
          title: this.$route.params.id
        }).then(e => {
          this.content = e.data.content
          this.type = e.data.type
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .article-wrap {

    .article-title {
      background: #fff;
      padding: 30px 0 30px 30px;
      font-size: 24px;
      color: #03a0fc;
      border-bottom: 1px solid #e6e6e6;
    }

    .content-wrap {
      padding: 30px;
      min-height: 200px;
      background: #fff;
    }
  }
</style>
